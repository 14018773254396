import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import Img from 'gatsby-image'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  header: {
    marginTop: 20,
    marginBottom: 60,
    backgroundColor: theme.palette.common.white,
  },
  textContent: {
  },
  pageContent: {
    ...theme.typography.body2,
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: 500,
    "& h1": {
      ...theme.typography.h1,
      color: theme.palette.primary.dark,
    },
    "& h2": {
      ...theme.typography.h2,
      color: theme.palette.primary.dark,
    },
    "& h3": {
      ...theme.typography.h3,
      color: theme.palette.primary.dark,
    },
    "& h4": {
      ...theme.typography.h4,
      color: theme.palette.primary.dark,
    },
    "& h5": {
      ...theme.typography.h5,
      color: theme.palette.primary.dark,
    },
    "& h6": {
      ...theme.typography.h6,
      color: theme.palette.primary.dark,
    },
    "& p": {
      margin: theme.spacing.unit * 2,
      "& img": {
        maxWidth: "100%",
        height: "auto",
      },
      "& a": {
        textDecoration: "none",
        color: theme.palette.primary.dark,
        display: 'inline-block',
        position: 'relative',
        "&:before": {
          backgroundColor: theme.palette.primary.dark,
          content: '""',
          height: '2px',
          position: 'absolute',
          bottom: -1,
          transition: 'width 0.3s ease-in-out',
          width: '0',
        },
        "&:hover": {
          color: theme.palette.primary.dark,
          "&::before": {
            width: '100%',
          }
        }
      }
    },
  },
  pageContentSub: {
    minWidth: 400,
    maxWidth: 800,
  },
  pageSub: {

  },
});

const getMarkdownComponent = data => {
  if (data.html) {
    return (<div dangerouslySetInnerHTML={{__html: data.html}} />)
  } else {
    return (<Markdown source={data.content} />)
  }
}

class PageContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loaded: false }
    this.handleScrollClick = this.handleScrollClick.bind(this)
  }

  handleScrollClick(event) {
    event.preventDefault()
    if (this.props.onScrollClick) {
      const { handler, id } = this.props.onScrollClick
      const scrollID = id || "header-sub"
      handler(scrollID)
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true,
      open: false,
    })
  }

  render() {
    const { classes, data } = this.props

    return (
      <Grid container spacing={0}>
        <Grid className={classes.header} item xs={12}>
          <Grid container className={classes.pageContent} justify="center">
          <Grid item className={classes.pageContentSub} xs={10} sm={10} lg={6}>
              <span>
                {data.image && data.image.fluid && <Img className="page__image" fluid={data.image.fluid} style={{ margin: "10px 0px 20px 0px" }} />}
                </span>
                {getMarkdownComponent(data)}
            </Grid>
          </Grid>
        </Grid>
        <Grid id="header-sub" className={classes.pageSub} item xs={12} sm={12}>
        </Grid>
      </Grid>
    )
  }
}

PageContent.propTypes = {
  classes: PropTypes.object.isRequired,
  onScrollClick: PropTypes.object,
  data:  PropTypes.object.isRequired
}

export default withStyles(styles)(PageContent)
