import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core";

// Components
import Layout from '../components/layout'
import PageContent from '../components/PageContent'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  next: {
    minHeight: 2000,
  }
});

const PageTemplate = ({ pageContext, classes }) => {
  const { description, title } = pageContext
  const pagedata = { title, description }
  const pagecontent = (
    <PageContent data={pageContext}/>
  )

  return (
    <Layout
      className={classes.root}
      pagedata={pagedata}
      // handler: this.handleScrollClick, id: "some-id" (defaults to hero-sub)
      // onScrollClick={{ handler: this.handleScrollClick }}
    >
      {pagecontent}
    </Layout>
  )
}

PageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    route: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
  }),
}

export default withStyles(styles)(PageTemplate)
